import React from 'react';
// import { MDBAnimation } from "mdbreact";
// import {Link} from "gatsby";
// import 'bootstrap';
// import Background from "../../assets/images/services/2d-3d-art/topbanner-bg.jpg";
const $ = typeof window !== `undefined` ? require('jquery') : null;

class DecoristRoomBuilder extends React.Component {
  /* constructor(props) {
        super(props);
    } */
  componentDidMount() {
    function scrollNav() {
      $('#nav').addClass('bg-white');
      // $('.nav-position').css("cssText", "background-color: #fff !important; box-shadow: 0px 5px 9px 0px rgba(196,196,196,0.33);");
      $('.nav-position').removeClass('navbar-transparent');
      $('.nav-position').addClass('navbar-solid');
      $('.nav-link').removeClass('nav-link-text');

      $('#logo-sticky').removeClass('hide');
      $('#logo-sticky').addClass('show');

      $('#logo').removeClass('show');
      $('#logo').addClass('hide');
    }
    $(document).ready(() => {
      $('.link-services').hover(
        function () {
          let imageId = $(this).attr('id');
          imageId = `/images/beatbugs/Icons/Hover/${  imageId  }.svg`;
          $(this).find('img').attr('src', imageId);
          $(this).css({
            color: '#ffffff',
          });
        },
        function () {
          let imageId = $(this).attr('id');
          imageId = `/images/beatbugs/Icons/${  imageId  }.svg`;
          $(this).find('img').attr('src', imageId);
          $(this).css({
            color: ' #787E84',
            'border-color': '#787E84',
          });
        }
      );

      $('document').ready(() => {
        scrollNav();
        window.addEventListener('scroll', (event) => {
          scrollNav();
        });
      });
    });
  }

  render() {
    return (
      <>
        <div>
          <section className="decorsit-banner">
            <img
              className="mw-100 mt-5"
              src={require('../../assets/images/decorist/background.png').default}
              alt=""
            />
          </section>
          {/* Decorist Client */}
          <section className="decorist-section-local">
            <div className="container">
              <div className="row d-flex justify-content-center align-items-center py-3 mx-0">
                <div className="col-lg-12 ">
                  <h1 className="heading">Decorist Room Builder</h1>

                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-lg-12  mt-2 ">
                      <p className="about-decorist ">
                        Automating Photorealism for Interior Design with a User-Friendly App
                      </p>
                    </div>
                  </div>
                  <div className="mt-3" />
                  <p className="client latoBold">The Client</p>
                  <p className="description">
                    <a href="https://www.decorist.com/" target="_blank" rel="noopener noreferrer">
                      Decorist
                    </a>{' '}
                    is a digital platform that matches customers with interior designers who can
                    assist in designing rooms within the individual style and budgetary constraints.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* Decorist Client  */}
          {/* Decorist Casestudy */}
          <section className="decorist-casestudy">
            <div className="container">
              {/* Decorist Goal */}
              <div className="row d-flex justify-content-center  py-md-5 mx-0">
                <div className="col-lg-4 col-md-4 heading-margin">
                  <p className="main-heading mb-0 justify-content-start">The Goal</p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 align-items-center">
                  <p className="heading">
                    To formulate a time-efficient process which would allow a designer to design a
                    room and get renders -- photorealistic images.
                  </p>
                  <p className="description">
                    Before the Room Builder app, the designers selected products to be used for the
                    room to create concepts, and also generate a shopping list for the customer. A
                    technical artist generated photorealistic images of the room, like the one
                    below, from the concepts using professional software such as 3ds Max and VRay.{' '}
                    <strong className="font-weight-bold">
                      The app would automate this rendering process and make it faster and more
                      efficient.
                    </strong>
                  </p>
                </div>
              </div>
              {/* Decorist Goal */}
              {/* Photorealism */}
              <div className="decorist-project-images">
                <img
                  className="mw-100"
                  src={require('../../assets/images/decorist/photorealism.jpg').default}
                  alt=""
                />
                <div className="image-description image-margin-top latoBold text-center">
                  A PHOTOREALISTIC IMAGE OF A ROOM CREATED IN 3DS MAX, RENDERED WITH VRAY
                </div>
              </div>
              {/* Photorealism */}
              {/* Decorist Work  */}
              <div className="row d-flex justify-content-center  py-md-5 mx-0">
                <div className="col-lg-4 col-md-4 heading-margin">
                  <p className="main-heading mb-0 justify-content-start">The Work</p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 align-items-center">
                  <p className="heading">
                    Creating an interior design application in Unity that allows designers to
                    quickly design a room in an optimized environment and then view photorealistic
                    results.
                  </p>
                  <p className="description">
                    Central to achieving the targeted process time was a need to reduce the
                    rendering time and the time taken to design a room. This involved designing a
                    rendering process that would handle item models efficiently and reduce
                    performance issues in the Unity application.
                  </p>
                  <h3 className="heading">Services Provided</h3>
                  <ul className="development">
                    <li>Unity App Development</li>
                    <li>Backend Development</li>
                    <li>Project Management</li>
                    <li>UI and UX Design</li>
                    <li>Quality Assurance</li>
                  </ul>
                </div>
                {/* Challenge  1 */}
                <div className="offset-lg-4 col-lg-8">
                  <p className="challenge">Challenge #1</p>
                  <p className="heading challenge-description">
                    Finalising the tech stack and then rapidly prototyping the 3D room creation
                    procedurally in 3ds Max using max scripting
                  </p>
                </div>
                <div className="decorist-project-images">
                  <img
                    className="mw-100"
                    src={require('../../assets/images/decorist/challenge-1.png').default}
                    alt=""
                  />
                  <div className="image-description image-margin-top latoBold text-center">
                    Procedural room generation in 3ds max with json file
                  </div>
                </div>
                {/* Challenge 1  */}
                {/* Challenge 2  */}
                <div className="offset-lg-4 col-lg-8">
                  <p className="challenge">Challenge #2</p>
                  <p className="heading challenge-description">
                    Building a scalable solution for managing multiple render requests using SQS
                    queues and load balancer, and reducing the AWS cost by 50% with an on-demand
                    instance usage.
                  </p>
                </div>
                {/* Challenge 2 */}
                {/* Challenge 3  */}
                <div className="offset-lg-4 col-lg-8">
                  <p className="challenge">Challenge #3</p>
                  <p className="heading challenge-description">
                    Automating pipeline workflows including render generation and converting obj
                    files to unity asset bundles using Jenkins.
                  </p>
                </div>
                <div className="decorist-project-images">
                  <img
                    className="mw-100"
                    src={require('../../assets/images/decorist/challenge-3.jpg').default}
                    alt=""
                  />
                  <div className="image-description image-margin-top latoBold text-center">
                    Render generation automation using Jenkins
                  </div>
                </div>
                {/* Challenge 3  */}
              </div>
              {/* Decorist Work  */}
              {/* Decorist Process */}
              <div className="row d-flex justify-content-center  py-md-5 mx-0">
                <div className="col-lg-4 col-md-4 heading-margin">
                  <p className="main-heading mb-0 justify-content-start">The Process</p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 align-items-center">
                  <p className="heading">Spec it out: Collaborative Product Design and R&amp;D</p>
                  <p className="description">
                    The project involved a significant amount of collaboration with the Decorist
                    team around figuring out wireframes, requirements and processes.
                  </p>
                  <p className="description">
                    Since we had limited experience with automation and photorealistic rendering, we
                    took a test-driven R&amp;D approach where we listed the most technically
                    challenging areas in the Project, both on the app side and around automation,
                    and spent the first few weeks solving those as individual pieces of the
                    project/system.
                  </p>
                  <p className="heading design">The Design Process</p>
                  <p className="description">
                    Designing the app was a thorough process involving collaboration between UX and
                    UI designers at Tintash and the designers at Decorist.
                  </p>
                  <p className="description">
                    An intensive review process was followed to ensure high quality UI design. In
                    addition, multiple cycles of user testing were initiated so that tweaks and
                    changes could be made to ensure optimum user experience for the designers.
                  </p>
                </div>
                {/* Wireframe Home Screen */}
                <div className="decorist-project-images">
                  <img
                    className="mw-100"
                    src={require('../../assets/images/decorist/wireframe-home-screen.png').default}
                    alt=""
                  />
                  <div className="image-description latoBold text-center">
                    WIREFRAMES DEVELOPED FOR HOME SCREEN
                  </div>
                </div>
                {/* Wireframe Home Screen */}
                {/* UI Home Screen */}
                <div className="decorist-project-images">
                  <img
                    className="mw-100"
                    src={require('../../assets/images/decorist/ui-home-screen.png').default}
                    alt=""
                  />
                  <div className="image-description image-margin-top latoBold text-center">
                    UI Design - HOME SCREEN
                  </div>
                </div>
                {/* UI Home Screen */}
                {/* Wireframe FLOOR PLAN SCREEN */}
                <div className="decorist-project-images">
                  <img
                    className="mw-100"
                    src={
                      require('../../assets/images/decorist/wireframe-floor-plan-screen.jpg')
                        .default
                    }
                    alt=""
                  />
                  <div className="image-description image-margin-top latoBold text-center">
                    WIREFRAMES DEVELOPED FOR FLOOR PLAN SCREEN
                  </div>
                </div>
                {/* Wireframe FLOOR PLAN SCREEN */}
                {/* UI FLOOR PLAN SCREEN */}
                <div className="decorist-project-images">
                  <img
                    className="mw-100"
                    src={require('../../assets/images/decorist/ui-floor-plan-screen.jpg').default}
                    alt=""
                  />
                  <div className="image-description image-margin-top latoBold text-center">
                    UI Design - FLOOR PLAN SCREEN
                  </div>
                </div>
                {/* UI FLOOR PLAN SCREEN */}
                {/* Technical Design */}
                <div className="offset-lg-4 col-lg-8 col-md-8 col-sm-12 align-items-center">
                  <p className="heading img-heading-text">Technical Design</p>
                  <p className="description">
                    Since the Room Builder application was developed in Unity, the high-quality item
                    models in Max needed to be mapped to low quality and optimized for Unity. We
                    came up with asset production guidelines for Decorist’s 3D artists so items
                    would show up as accurately as possible in the Room Builder app in terms of
                    textures, color and geometry.
                  </p>
                  <p className="description">
                    Remote automation machines were used to create the final photorealistic images
                    to be shown to customers and process the item models for use in the Unity app.
                  </p>
                  <p className="description">
                    We also designed and created an online inventory system that would store the 3D
                    models and information for the items to be used in the rooms. This portal
                    incorporates databases containing information against each item such as its ID,
                    the vendor and an item category.
                  </p>
                </div>
                {/* Technical Design */}
                {/* Architectural Design */}
                <div className="decorist-project-images">
                  <img
                    className="mw-100"
                    src={require('../../assets/images/decorist/architectural.jpg').default}
                    alt=""
                  />
                  <div className="image-description image-margin-top latoBold text-center">
                    Architectural Diagram
                  </div>
                </div>
                {/* Architectural Design */}
                {/* Defining asset workflow */}
                <div className="offset-lg-4 col-lg-8 col-md-8 col-sm-12 align-items-center">
                  <p className="heading img-heading-text">Defining asset workflow</p>
                  <p className="description">
                    To show items in the rooms being designed in the Unity app, an asset workflow
                    was defined that would map item models in the Unity app to item models in 3DS
                    Max which would show up in the final renders. An asset bundle would be uploaded
                    on the inventory a management system which would allow the app to access item
                    models.
                  </p>
                </div>
                {/* Defining asset workflow */}

                {/* Rendering Quality Assurance */}
                <div className="offset-lg-4 col-lg-8 col-md-8 col-sm-12 align-items-center">
                  <p className="heading img-heading-text">Rendering Quality Assurance</p>
                  <p className="description">
                    We came up with a novel way to integrate Slack with our rendering process
                    through automated messages which served as a convenient way for the team to
                    monitor assets and rooms being rendered. This helped us refine the process
                    through testing and experimentation. Slack notifications also served as an alarm
                    for the render failures.
                  </p>
                </div>
                {/* Rendering Quality Assurance */}
                {/* Slack Integration */}
                <div className="decorist-project-images">
                  <img
                    className="mw-100"
                    src={
                      require('../../assets/images/decorist/render-request-failed-scenario.png')
                        .default
                    }
                    alt="Render Request Failed Scenario"
                  />
                  <div className="image-description image-margin-top latoBold text-center">
                    Render Request Failed Scenario
                  </div>
                </div>
                {/* Slack Integration */}
                {/* Remote Instance / Optimization of Model */}
                <div className="offset-lg-4 col-lg-8 col-md-8 col-sm-12 align-items-center">
                  <p className="heading img-heading-text">Remote Instance Optimization</p>
                  <p className="description">
                    An area of optimization was using the optimal remote machines and configurations
                    to generate models and images as efficiently as possible in terms of performance
                    and cost.
                  </p>
                  <p className="heading design">Optimization of Models</p>
                  <p className="description">
                    We developed our own Max script and integrated a built-in asset decimation tool
                    named Simplygon to minimize the vertex count of the Unity models and convert
                    high quality textures to textures that could be used in Unity.
                    <br />
                    This process also involved extensive quality assurance where every model was
                    checked for texture and geometry issues which could then be addressed.
                  </p>
                </div>
                <div className="decorist-project-images">
                  <img
                    className="mw-100"
                    src={require('../../assets/images/decorist/model-optimization.jpg').default}
                    alt=""
                  />
                </div>
                {/* Remote Instance / Optimization of Model */}
              </div>
              {/* Decorist Process */}
              {/* Project Timeline */}
              {/* <div className="offset-lg-4 col-lg-8 col-md-8 col-sm-12 align-items-center">
                <p className="heading img-heading-text">Project Timeline:</p>
                <p className="description">
                  Here is the breakdown of the timeline that represents
                  different stages of the project.
                </p>
                <mark className="description">Placeholder for time</mark>
              </div> */}
              {/* Project Timeline */}
              {/* Development Lifecycle */}
              <div className="offset-lg-4 col-lg-8 col-md-8 col-sm-12 align-items-center">
                <p className="heading img-heading-text">Development Cycle:</p>
                <p className="description">
                  At Tintash, we follow the best-in-class software development processes optimized
                  for speed, robustness, scalability, repeatability and cost effectiveness. We
                  ensure seamless cross-functional collaboration at all stages of the development
                  lifecycle.
                </p>
              </div>
              <div className="decorist-project-images text-center">
                <img
                  className="mw-100"
                  src={require('../../assets/images/decorist/development-cycle.jpg').default}
                  alt="development cycle"
                />
              </div>
              {/* Development Lifecycle */}
              {/* The Team */}
              <div className="row d-flex justify-content-center  py-md-5 mx-0">
                <div className="col-lg-4 col-md-4 heading-margin">
                  <p className="main-heading mb-0 justify-content-start">The Team</p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 align-items-center">
                  <p className="description">
                    The Room Builder team comprised of Unity Engineers, backend server and
                    automation engineers, a system architect, a full time dedicated QA engineer, a
                    technical project manager and an associate project manager, a UI artist and a UX
                    designer.
                  </p>
                </div>
                <div className="decorist-project-images">
                  <img
                    className="mw-100"
                    src={require('../../assets/images/decorist/the-room-builder-team.jpg').default}
                    alt="THE ROOM BUILDER TEAM"
                  />
                  <div className="pt-2 image-description latoBold text-center">
                    THE ROOM BUILDER TEAM
                  </div>
                </div>
              </div>
              {/* The Team */}
              {/* Decorist Result  */}
              <div className="row d-flex justify-content-center   py-md-5 mx-0">
                <div className="col-lg-4 col-md-4 heading-margin">
                  <p className="main-heading mb-0 justify-content-start">The Results</p>
                </div>
                <div className=" col-lg-8 col-md-8 col-sm-12 align-items-center" />
                {/* Final Renders */}
                <div className="decorist-project-images render-image mb-0">
                  <div className="container">
                    <div className="row d-flex justify-content-center">
                      <div className="col-lg-6 col-12 col-md-6 pr-lg-0 pl-lg-4 py-2">
                        <img
                          src={require('../../assets/images/decorist/unity-camera-1.png').default}
                          alt=""
                        />
                      </div>
                      <div className="col-lg-6 col-12 col-md-6 pl-lg-0 py-2">
                        <img
                          src={
                            require('../../assets/images/decorist/unity-camera-render-1.jpg')
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <div className="col-lg-6 col-12 col-md-6 pr-lg-0 pl-lg-4 py-2">
                        <img
                          src={require('../../assets/images/decorist/unity-camera-2.png').default}
                          alt=""
                        />
                      </div>
                      <div className="col-lg-6 col-12 col-md-6 pl-lg-0 py-2">
                        <img
                          src={
                            require('../../assets/images/decorist/unity-camera-render-2.jpg')
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <div className="col-lg-6 col-12 col-md-6 pr-lg-0 pl-lg-4 py-2">
                        <img
                          src={require('../../assets/images/decorist/unity-camera-3.png').default}
                          alt=""
                        />
                      </div>
                      <div className="col-lg-6 col-12 col-md-6 pl-lg-0 py-2">
                        <img
                          src={
                            require('../../assets/images/decorist/unity-camera-render-3.jpg')
                              .default
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="image-description image-margin-top latoBold text-center render-text">
                      A FULLY FURNISHED ROOM IN UNITY (LEFT) AND ITS FINAL RENDERS (RIGHT)
                    </div>
                  </div>
                </div>
                {/* Final Renders */}
                <div className="offset-lg-4 col-lg-8 col-md-12  align-items-center">
                  <p className="heading design">From Two Days to Two Hours</p>
                  <p className="description">
                    Over the course of this project, the team addressed all objectives identified by
                    the team.
                  </p>
                  <p className="heading">Render time:</p>
                  <p className="description">
                    The time taken to render a fully furnished room was reduced to an average of
                    10-15 mins per room.
                  </p>
                  <p className="heading">Room design process:</p>
                  <p className="description">
                    The time taken to design a room was reduced to 30-40 mins which included time
                    taken to load item models being used in the room and resolving any performance
                    issues that might slow down the app.
                  </p>
                  <p className="description">
                    Fulfilling both these objectives allowed us to easily meet the target set by the
                    client for the room design and rendering process. The entire process now takes
                    1-2 hours only.
                  </p>
                </div>
                <div className="row d-flex justify-content-center align-items-center py-5 mx-0 px-0">
                  <div className="col-12">
                    <img
                      className="mw-100 mb-4"
                      src={require('../../assets/images/decorist/quote.svg').default}
                      alt=""
                    />
                    <p className="description">
                      "It has been a distinct pleasure working with the Tintash team. The product
                      solves a critical conversion issue by producing photo-realistic images that
                      are comparable (sometimes better) to those created by a 3D artist. Even though
                      the Room Builder team is located on another continent, we've been able to
                      collaborate effectively and efficiently; the Tintash team has even made
                      suggestions on newer technologies to us."
                    </p>
                    {/* client Name/Designation */}
                    <div className="col box d-flex d-row user mw-100">
                      <div className="text-left pt-1">
                        <p className=" user-name mb-0">Hima Sunkara</p>
                        <p className=" user-designation">SVP, Product and Technology, Decorist</p>
                      </div>
                      {/* <div className="bg-primary col align-self-end horizontal-line"></div> */}
                    </div>
                    {/* /client Name/Designation */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default DecoristRoomBuilder;
